import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, take } from 'rxjs';
import { HandshakeParams } from '../models/handshakeParams';
import { HandshakeResponse } from '../models/handshakeResponse';

@Injectable({
    providedIn: 'root',
})
export class HandshakeService {
    selected: HandshakeResponse;

    constructor(private fns: AngularFireFunctions) {}

    checkShortenUrl(
        shortenUrl: HandshakeParams
    ): Observable<HandshakeResponse> {
        const callable =
            this.fns.httpsCallable<HandshakeParams>('app-handshake');
        return callable(shortenUrl).pipe(take(1));
    }
}
