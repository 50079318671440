import {
    DEFAULT_CURRENCY_CODE,
    enableProdMode,
    importProvidersFrom,
    LOCALE_ID,
} from '@angular/core';
import { environment } from 'environments/environment';

import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';

import { LayoutModule } from 'app/layout/layout.module';
import { CoreModule } from 'app/core/core.module';
import { mockApiServices } from 'app/mock-api';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { appConfig } from 'app/core/config/app.config';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseModule } from '@fuse';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { appRoutes } from 'app/app.routing';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import {
    AngularFireFunctionsModule,
    REGION,
} from '@angular/fire/compat/functions';
import {
    getAnalytics,
    provideAnalytics,
    ScreenTrackingService,
    UserTrackingService,
} from '@angular/fire/analytics';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { getFunctions, provideFunctions } from '@angular/fire/functions';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    enableTracing: true,
};
const googleMapsParams = {
    apiKey: environment.googleMapsApiKey,
    libraries: ['places'],
    language: 'de',
    region: 'DE',
};

if (environment.production) {
    enableProdMode();
}

function bootstrap() {
    bootstrapApplication(AppComponent, {
        providers: [
            importProvidersFrom(
                BrowserModule.withServerTransition({ appId: 'serverApp' }),
                RouterModule.forRoot(appRoutes, routerConfig),
                ReactiveFormsModule,
                // Material
                MatSnackBarModule,
                MatButtonModule,
                MatInputModule,
                MatIconModule,
                // Fuse, FuseConfig & FuseMockAPI
                FuseModule,
                FuseConfigModule.forRoot(appConfig),
                FuseMockApiModule.forRoot(mockApiServices),
                // Core module of your application
                CoreModule,
                // Layout module of your application
                LayoutModule,
                provideAnalytics(() => getAnalytics()),
                provideAuth(() => getAuth()),
                provideFirestore(() => getFirestore()),
                providePerformance(() => getPerformance()),
                provideFirebaseApp(() => initializeApp(environment.firebase)),
                AngularFireModule.initializeApp(environment.firebase),
                AngularFireFunctionsModule,
                provideStorage(() => getStorage()),
                // MatGoogleMapsAutocompleteModule
                MatGoogleMapsAutocompleteModule.forRoot(
                    environment.googleMapsApiKey
                ),
                // Service worker registration
                ServiceWorkerModule.register('ngsw-worker.js', {
                    enabled: environment.production,
                    registrationStrategy: 'registerWhenStable:30000',
                }),
                MatProgressSpinnerModule
            ),
            ScreenTrackingService,
            UserTrackingService,
            { provide: REGION, useValue: 'europe-west3' },
            { provide: LOCALE_ID, useValue: 'de' },
            { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
            provideAnimations(),
        ],
    }).catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}
